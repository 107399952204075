import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import AuthContext from '../../store/auth-context';

function ProtectAdm() {
  const { isAdmin } = useContext(AuthContext);

  return !isAdmin ? <Navigate to="/user/dashboard" replace /> : <Outlet />;
}

export default ProtectAdm;
