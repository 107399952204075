const swRegister = async () => {
  if (!('serviceWorker' in navigator)) {
    console.log('Service Worker not supported in the browser');
    return;
  }

  try {
    const reg = await navigator.serviceWorker.register('./sw.js');
    setInterval(() => {
      reg.update();
    }, 1000 * 60 * 1);
    // console.log('Service worker registered');
  } catch (error) {
    // console.log('Failed to register service worker', error);
  }
};

export default swRegister;
