import axios from 'axios';

// export const apiUrl = 'http://localhost:8000';
export const apiUrl = 'https://simakapi.dareliman.or.id';
// export const apiUrl = 'https://simakapiorid.dareliman.net';

const instance = axios.create({
  baseURL: apiUrl,
});

instance.defaults.withCredentials = true;

export default instance;
