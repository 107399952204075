import axios from './axios';

export const sendData = async (fetchAPI, dataInput, method = 'POST') => {
  const resType = fetchAPI.includes('export') ? { responseType: 'blob' } : null;

  let response;

  if (fetchAPI === 'login' || fetchAPI === 'register') {
    await axios.get('/sanctum/csrf-cookie');
  }

  // await axios.get('/sanctum/csrf-cookie');

  if (method === 'GET') {
    response = await axios
      .get(`/api/${fetchAPI}`, resType)
      .catch(err => err.response);
  }

  if (method === 'POST') {
    response = await axios
      .post(`/api/${fetchAPI}`, dataInput)
      .catch(err => err.response);
  }

  if (method === 'PUT') {
    response = dataInput.idSerie
      ? await axios
          .put(`/api/${fetchAPI}/${dataInput.idSerie}`, dataInput)
          .catch(err => err.response)
      : await axios
          .put(`/api/${fetchAPI}`, dataInput)
          .catch(err => err.response);
  }

  if (response.status === 200 || response.status === 201) {
    return response.data;
  } else {
    // console.log(response);
    // eslint-disable-next-line no-throw-literal
    throw {
      message: response || 'maaf ada gangguan',
      status: response.status,
    };
  }
};
